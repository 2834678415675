.sideBar.active.active {
  color: #ffffff !important;
  background-color: var(--primary);
  border-radius: 30px 0 0 30px;
}
.sideBar.active.active svg {
  color: #ffffff;
}

.sideBar.active.nested.active {
  color: var(--primary) !important;
  background-color: #ffffff !important;
}
.sideBar.active.nested.active svg {
  color: var(--primary);
}

.sideBar.active.active {
  color: #ffffff !important;
  background-color: var(--primary);
  border-radius: 30px 0 0 30px;
}
.sideBar.active svg {
  color: #ffffff;
}

.sideBar.active.nested.active {
  color: var(--primary) !important;
  background-color: #ffffff !important;
}
.sideBar.active.nested.active svg {
  color: var(--primary);
}
